@import 'lms-ui/globals.css';
@import 'lms-ui/theme.scss';

//
// EFFECTS
//
body {
  background-color: var(--color-chart-light-grey-one);
}

body .mediumScreen {
  display: inherit;
  @include mobile {
    display: none;
  }
}

body .smallScreen {
  display: none;
  @include mobile {
    display: inherit;
  }
}

body .mediumFlexScreen {
  display: flex;
  @include mobile {
    display: none;
  }
}

body .smallFlexScreen {
  display: none;
  @include mobile {
    display: flex;
  }
}

body .tabletScreen {
  @include tablet {
    display: inherit;
  }
}

body .notTabletScreen {
  @include tablet {
    display: none;
  }
}

.highlightText {
  color: var(--color-chart-sevres-blue);
}
